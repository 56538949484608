import React from "react";
import Box from "@mui/material/Box";
import UtilityTag from "../UtilityTag";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
// Styles must use direct files imports
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
import LinkIcon from "@mui/icons-material/Link"; // Pagination module
import UtilityDialog from "./UtilityDialog";
import "./styles.css";
import { Pagination } from "swiper/modules";
import UI from "../../../@components/UI";
import { useTranslation } from "react-i18next";

const Item = ({
  onSelect,
  item,
  customAction,
  customStyles = null,
  showRedeemed,
  showCustomLinks,
}) => {
  const { t, i18n } = useTranslation();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const maxLength = isMobile ? 90 : 100;

  return (
    <Box
      onClick={() => onSelect()}
      sx={{
        cursor: "pointer",
        width: "350px",
        height: "190px",
        borderRadius: (theme) => theme.borderRadius.primary,
        transition: "all 0.5s ease-in-out",
        "&:hover": {
          background: customStyles
            ? customStyles.card_background_color
            : (theme) => theme.palette.bgTransparent.secondary,
        },
        background: customStyles
          ? customStyles.card_background_color
          : (theme) => theme.palette.bgTransparent.primary,
        color: customStyles
          ? customStyles.card_text_color
          : "rgba(217, 217, 217, 0.06)",
        display: "flex",
        flexDirection: "column",
        textAlign: "left",
      }}
    >
      <Box sx={{ p: "24px 18px" }}>
        <Typography
          variant={"h8"}
          sx={{
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
            maxWidth: { md: "240px", mobile: "220px" },
            mb: "12px",
            display: "block",
          }}
        >
          <UI.OverflowTip>
            {item?.name_slo !== null &&
            item?.name_slo !== undefined &&
            item?.name_slo !== "" &&
            i18n.language === "sl"
              ? item?.name_slo
              : item?.name || ""}
          </UI.OverflowTip>
        </Typography>
        <Box sx={{ display: "flex", mb: "20px", mt: "12px" }}>
          <Box>
            <UtilityTag
              customStyles={
                customStyles
                  ? {
                      backgroundColor: customStyles?.perks_badge_color,
                      perks_badge_text_color:
                        customStyles?.perks_badge_text_color,
                    }
                  : null
              }
              utilityType={item.type}
              sx={{ p: "0px 14px", height: { md: "23px", mobile: "21px" } }}
            />
          </Box>
          {showRedeemed && (
            <Box sx={{ display: "flex" }}>
              <UI.Tag
                sx={{
                  ml: "10px",
                  height: { md: "23px", mobile: "21px" },
                  padding: "0px 14px",
                  backgroundColor: item?.is_redeemed
                    ? (theme) => theme.palette.tag.yellow
                    : (theme) => theme.palette.tag.green,
                }}
              >
                {item?.is_redeemed
                  ? t("commons.redeemed")
                  : t("commons.available")}
              </UI.Tag>
            </Box>
          )}
          {showCustomLinks && customAction && (
            <Box
              sx={{
                ml: "10px",
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer",
                }}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  window.open(customAction.value, "_blank");
                }}
              >
                <Tooltip title={customAction.title} arrow>
                  <Box
                    sx={{
                      width: "23px",
                      height: "23px",
                      borderRadius: "50%",
                      backgroundColor: "rgba(148, 77, 255, 1);",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <LinkIcon sx={{ color: "#fff", fontSize: "16px" }} />
                  </Box>
                </Tooltip>
              </Box>
            </Box>
          )}
        </Box>

        {item.description != null && (
          <Box sx={{ lineHeight: "1.2", mb: "8px" }}>
            <Typography
              variant={"subtitle3"}
              sx={{
                maxHeight: "75px",
                overflow: "hidden",
              }}
            >
              {item?.description_slo !== null &&
              item?.description_slo !== undefined &&
              item?.description_slo !== "" &&
              i18n.language === "sl"
                ? item.description_slo?.length > maxLength
                  ? item.description_slo?.slice(0, maxLength) + "... "
                  : item.description_slo + " "
                : item.description.length > maxLength
                ? item.description?.slice(0, maxLength) + "... "
                : item.description + " " || ""}
            </Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
};

const Utilities = ({
  utilities,
  currency,
  gasFeesPayedBySystem,
  customStyles,
  hidePagination,
  showRedeemed = false,
  showCustomLinks = true,
}) => {
  const [selectedUtility, setSelectedUtility] = React.useState(null);

  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));
  return (
    <>
      {utilities && utilities.length === 0 && <Box></Box>}
      {utilities && !isMobile && utilities.length === 1 && (
        <Item
          showCustomLinks={showCustomLinks}
          showRedeemed={showRedeemed}
          customStyles={customStyles}
          onSelect={() => setSelectedUtility(utilities[0])}
          item={utilities[0]}
          customAction={utilities[0]?.custom_info_fields?.find(
            (field) => field.input_type === "button"
          )}
        />
      )}
      {utilities && !isMobile && utilities.length === 2 && (
        <Box sx={{ display: "flex" }}>
          <Item
            showCustomLinks={showCustomLinks}
            showRedeemed={showRedeemed}
            customStyles={customStyles}
            onSelect={() => setSelectedUtility(utilities[0])}
            item={utilities[0]}
            customAction={utilities[0]?.custom_info_fields?.find(
              (field) => field.input_type === "button"
            )}
          />
          <Box sx={{ ml: "20px" }}>
            <Item
              showCustomLinks={showCustomLinks}
              showRedeemed={showRedeemed}
              customStyles={customStyles}
              onSelect={() => setSelectedUtility(utilities[1])}
              item={utilities[1]}
              customAction={utilities[1]?.custom_info_fields?.find(
                (field) => field.input_type === "button"
              )}
            />
          </Box>
        </Box>
      )}
      {utilities && !isMobile && utilities.length === 3 && (
        <Box sx={{ display: "flex" }}>
          <Item
            showCustomLinks={showCustomLinks}
            showRedeemed={showRedeemed}
            customStyles={customStyles}
            onSelect={() => setSelectedUtility(utilities[0])}
            item={utilities[0]}
            customAction={utilities[0]?.custom_info_fields?.find(
              (field) => field.input_type === "button"
            )}
          />
          <Box sx={{ ml: "20px" }}>
            <Item
              showCustomLinks={showCustomLinks}
              showRedeemed={showRedeemed}
              customStyles={customStyles}
              onSelect={() => setSelectedUtility(utilities[1])}
              item={utilities[1]}
              customAction={utilities[1]?.custom_info_fields?.find(
                (field) => field.input_type === "button"
              )}
            />
          </Box>
          <Box sx={{ ml: "20px" }}>
            <Item
              showCustomLinks={showCustomLinks}
              showRedeemed={showRedeemed}
              customStyles={customStyles}
              onSelect={() => setSelectedUtility(utilities[2])}
              item={utilities[2]}
              customAction={utilities[2]?.custom_info_fields?.find(
                (field) => field.input_type === "button"
              )}
            />
          </Box>
        </Box>
      )}
      {((utilities && utilities.length > 3) || (isMobile && utilities)) && (
        <Swiper
          pagination={true}
          spaceBetween={28}
          grabCursor={true}
          modules={hidePagination ? [] : [Pagination]}
          slidesPerView={isMobile ? (utilities.length === 1 ? 1 : 1.2) : 3}
          className="nftSwiper"
        >
          {utilities?.map((item, index) => {
            const customAction = item.custom_info_fields?.find(
              (field) => field.input_type === "button"
            );

            return (
              <SwiperSlide key={item.id}>
                <Item
                  showCustomLinks={showCustomLinks}
                  showRedeemed={showRedeemed}
                  customStyles={customStyles}
                  onSelect={() => setSelectedUtility(item)}
                  item={item}
                  customAction={customAction}
                />
              </SwiperSlide>
            );
          })}
        </Swiper>
      )}
      <UtilityDialog
        showCustomLinks={showCustomLinks}
        gasFeesPayedBySystem={gasFeesPayedBySystem}
        customStyles={customStyles}
        utility={selectedUtility}
        currency={currency}
        onClose={() => setSelectedUtility(null)}
      />
    </>
  );
};

export default Utilities;
